import { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const useTracker = () => {
  const location = useLocation();

  // if cookies have previously been accepted we track
  if (getCookieConsentValue() === 'true') {
    ReactGA.initialize('G-1FTXYVQ5MW');
  } else {
    // else we proactivley delete any existing cookies
    Cookies.remove('_ga', { path: '/', domain: '.serverlessadvocate.com' });
    Cookies.remove('_gat', { path: '/', domain: '.serverlessadvocate.com' });
    Cookies.remove('_gid', { path: '/', domain: '.serverlessadvocate.com' });
    Cookies.remove('_ga_1FTXYVQ5MW', {
      path: '/',
      domain: '.serverlessadvocate.com',
    });
  }

  // only in affect if the user has accepted the cookie banner
  // and ga4 initialised
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    });
  }, [location.pathname]);
};

export default useTracker;
