import CookieConsent, { Cookies } from 'react-cookie-consent';

import ReactGA from 'react-ga4';

// on accept we enable ga4
const onAccept = () => {
  ReactGA.initialize('G-1FTXYVQ5MW');
};

// on decline we delete the cookies
const onDecline = () => {
  Cookies.remove('_ga', { path: '/', domain: '.serverlessadvocate.com' });
  Cookies.remove('_gat', { path: '/', domain: '.serverlessadvocate.com' });
  Cookies.remove('_gid', { path: '/', domain: '.serverlessadvocate.com' });
  Cookies.remove('_ga_1FTXYVQ5MW', {
    path: '/',
    domain: '.serverlessadvocate.com',
  });
};

const ConsentBar = () => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="I Accept and Understand"
        enableDeclineButton={true}
        declineButtonText="Decline"
        onAccept={onAccept}
        onDecline={onDecline}
        flipButtons={true}
        style={{ background: 'rgb(97, 97, 97, 0.9)' }}
        declineButtonStyle={{
          color: '#fff',
          background: '#616161',
          fontSize: '16px',
          borderStyle: 'solid',
          borderColor: 'rgb(129 126 126)',
          borderWidth: '1px',
          borderRadius: '3px',
        }}
        buttonStyle={{
          color: '#fff',
          background: '#000',
          fontSize: '16px',
          borderStyle: 'solid',
          borderColor: '#000',
          borderWidth: '1px',
          borderRadius: '3px',
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{' '}
      </CookieConsent>
    </>
  );
};

export default ConsentBar;
