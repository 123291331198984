import { AppBar, Box, Button, Grid, Toolbar, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import MessageIcon from '@mui/icons-material/Message';
import Fab from '@mui/material/Fab';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../shared/scroll-to-top';

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const Footer = () => {
  const theme = useTheme();
  const backgroundColor = theme.palette.background.default;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: backgroundColor, top: 'auto', bottom: 0 }}
      >
        <Toolbar>
          <Link to="/contact">
            <StyledFab size="large" color="info" aria-label="add">
              <MessageIcon />
            </StyledFab>
          </Link>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={4} sx={{ padding: '50px 0px' }}>
              <Typography variant="body1" color="textSecondary">
                Social Links
              </Typography>
              <Button
                onClick={scrollToTop}
                href="https://www.youtube.com/@serverlessadvocate"
                target="_blank"
                color="primary"
              >
                YouTube.
              </Button>
              <Button
                onClick={scrollToTop}
                href="https://twitter.com/LeeJamesGilmore"
                target="_blank"
                color="primary"
              >
                Twitter/X.
              </Button>
              <Button
                onClick={scrollToTop}
                href="https://www.linkedin.com/in/lee-james-gilmore/"
                target="_blank"
                color="primary"
              >
                LinkedIn.
              </Button>
              <Button
                onClick={scrollToTop}
                href="https://github.com/leegilmorecode/"
                target="_blank"
                color="primary"
              >
                GitHub.
              </Button>
              <Button
                onClick={scrollToTop}
                href="https://instagram.com/serverlessadvocate"
                target="_blank"
                color="primary"
              >
                Instagram.
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ padding: '50px 0px' }}>
              <Typography variant="body1" color="textSecondary">
                © {new Date().getFullYear()} Serverless Advocate. All rights
                reserved.
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ padding: '50px 0px' }}>
              <Typography variant="body1" color="textSecondary">
                Website
              </Typography>
              <Button
                onClick={scrollToTop}
                component={Link}
                to="/"
                color="primary"
              >
                Home.
              </Button>
              <Button
                onClick={scrollToTop}
                component={Link}
                to="/services"
                color="primary"
              >
                Services.
              </Button>
              <Button
                href="https://blog.serverlessadvocate.com/"
                target="_blank"
                color="primary"
              >
                Blog.
              </Button>
              <Button
                onClick={scrollToTop}
                component={Link}
                to="/contact"
                color="primary"
              >
                Contact.
              </Button>
              <Button
                onClick={scrollToTop}
                component={Link}
                to="/privacy-policy"
                color="primary"
              >
                Privacy Policy.
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Footer;
