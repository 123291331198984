import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

const Patterns = lazy(() => import('../pages/patterns'));

const Main = lazy(() => import('../pages/main'));
const Contact = lazy(() => import('../pages/contact'));
const NotFound = lazy(() => import('../pages/not-found'));
const Services = lazy(() => import('../pages/services'));
const Privacy = lazy(() => import('../pages/privacy'));

const routes = (): JSX.Element => {
  return (
    <>
      <Suspense
        fallback={
          <>
            <CircularProgress color="primary" />
          </>
        }
      >
        <Routes>
          <Route path="/" Component={Main} />
          <Route path="/contact" Component={Contact} />
          <Route path="/services" Component={Services} />
          <Route path="/patterns" Component={Patterns} />
          <Route path="/privacy-policy" Component={Privacy} />
          <Route path="*" Component={NotFound} />
        </Routes>
      </Suspense>
    </>
  );
};
export default routes;
