import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';

export const theme = (darkMode = false): Theme => {
  const themeOptions: ThemeOptions = {
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: darkMode ? '#000' : '#fff',
      },
      text: {
        primary: darkMode ? '#fff' : '#000',
      },
      primary: {
        main: darkMode ? '#fff' : '#5E17EB',
        light: '#9465f2',
        dark: '#ffffff',
        contrastText: '#ffffff',
      },
      secondary: {
        main: darkMode ? '#fff' : '#000',
        light: '#000000',
        dark: '#ffffff',
        contrastText: '#ffffff',
      },
      info: {
        main: '#5E17EB',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            textTransform: 'capitalize',
          },
        },
      },
    },
  };

  return createTheme(themeOptions);
};
